// TODO: Unit conversions litre -> kg
// e.g. white rice: 1L ~= 780g
//
// TODO: soft restrictions on some quantities like salt/sodium, eggs per day (~3 max)
//
// TODO: indicate incomplete food rows with yellow/red on attributes cols
//
// TODO: custom attributes (EUR/USD, nitrates/nitrites, notes, etc.)
//
// TODO: view all food items in DB, sort by attributes

import 'babel-polyfill';
import store from 'store';

const defaultFoodDB = require('./nutritionvalue.json');

const T = document.querySelector('tbody#main')
const Totals = document.querySelector('tbody#totals')

function assert(expr, message) {
    if (!expr) throw new Error(`Assertion error: ${message}`);
}

// Pretty print a number to desired precision
const pp = (x, precision = 2) => (+x.toPrecision(precision)).toLocaleString();

const units = 'kcal g g g g g'.split(/ /g);
const rowNutrients = 'calories carbohydrate protein fat fiber unit_size'.split(/ /g)
const rowPrecision = [0,1,1,1,1,1].map(x => (n => Math.round(n * Math.pow(10,x)) / Math.pow(10,x) ))

assert(units.length == rowNutrients.length)
assert(units.length == rowPrecision.length)

function *elementAncestors(elem) {
    while (elem !== null) {
        yield elem;
        elem = elem.parentNode;
    }
}
const getRowFromEvent = ev => Array.from(elementAncestors(ev.target)).filter(x => x.tagName === 'TR')[0];

const defaults = {
    quantity: 1,
    unit_size: 100,
}

const fillFoodFromDB = (rowElem, foodName) => {
    const res = store.get(`food/${foodName}`)
    if (!res) return;

    for (const [k,v] of Object.entries(defaults)) {
        const e = rowElem.querySelector(`input[name="${k}"]`)
        assert(e)
        // Don't overwrite a valid quantity, if set
        if (e.value === '') e.value = v;
    }

    const inputs = Array.from(rowElem.querySelectorAll('.cell > input'))
    assert(inputs.length === rowNutrients.length);
    for (const i in inputs) {
        if (rowNutrients[i] in res) {
            const precision = rowPrecision[i];
            inputs[i].value = precision(res[rowNutrients[i]]);
        }
    }
}

const calculateTotals = () => {
    const rows = Array.from(T.querySelectorAll('tr'))
    const totals = [0,0].concat(units.map(_ => 0))
    for (const row of rows) {
        const rowI = Array.from(row.querySelectorAll('input')).map(e => e.value)
        const quantity = rowI[0] === 0 ? 0 : (rowI[0] || 1)
        const gramsPerUnit = rowI[rowI.length-1] || 100;
        const weightModifier = gramsPerUnit / 100;
        for (const i in rowI) {
            totals[i] += quantity * weightModifier * rowI[i];
        }
    }

    // const asd = JSON.stringify(totals.slice(2))
    Totals.innerHTML = `<tr><td colspan=2>Total</td>
    ${totals.slice(2, totals.length-1).map((x,i) => `<td>${pp(x)} ${units[i]}</td>`).join("\n")}
    <td colspan="2">
    <!--
    <select>
    <option></option>
    </select>
    -->
    </td>
    </tr>`
}

const updateTotals = ev => {
    const rowElem = getRowFromEvent(ev)

    if (ev && ev.target.name === "food") {
        fillFoodFromDB(rowElem, ev.target.value.trim().toLowerCase())
        // Add an empty row if this filled the last row
        if (rowElem === T.children[T.children.length-1]) {
            rowElem.insertAdjacentHTML('afterEnd', rowTemplate)
        }
    }
    calculateTotals();
}

function html(htmlStr) {
    const template = document.createElement('template');
    template.innerHTML = htmlStr;
    return template.content.firstElementChild;
}



const rowTemplate = `
<tr>
<td><input name="quantity" type="number" min="0" max="100" value="${defaults.quantity}" /></td>
<td><input name="food" type="text" minlength="1" maxlength="100" list="food-list" autocomplete="off" /></td>
<td><div class="cell"><input name="calories" type="number" min="0" max="2000"/> kcal</div></td>
<td><div class="cell"><input name="carbohydrate" type="number" step="0.1" min="0" max="100"/> g</div></td>
<td><div class="cell"><input name="protein" type="number" step="0.1" min="0" max="100"/> g</div></td>
<td><div class="cell"><input name="fat" type="number" step="0.1" min="0" max="100"/> g</div></td>
<td><div class="cell"><input name="fiber" type="number" step="0.1" min="0" max="100"/> g</div></td>
<td><div class="cell"><input name="unit_size" type="number" step="0.1" min="0.1" max="1000" value="${defaults.unit_size}" /> g</div></td>
<td class="cell">
<button name="add">Add</button>
<button name="remove">Remove</button>
<button name="forget">Forget food</button>
</td>
</tr>
`

let persistGranted = false;

if (navigator.storage && navigator.storage.persisted) {
    Promise.all([
        navigator.storage.persisted(),
        navigator.permissions.query({name: "persistent-storage"})
    ]).then(([persisted, permission]) => {
        persistGranted = persisted && permission.status === 'granted';
    });
}

function updateDataList() {
    let db = []
    store.each((x,k) => k.startsWith('food/') && db.push(x))

    const sorted = db.sort((a,b) => a.food.toLowerCase() < b.food.toLowerCase() ? -1 : +1)
    const foods = `
    <datalist id="food-list">
    ${sorted.map(x => `<option>${x.food}</option>`).join("\n")}
    </datalist>
    `
    document.querySelector('#food-list').replaceWith(html(foods))
}

const foodKey = entry => `food/${entry.food.toLowerCase()}`
const saveFood = entry => store.set(foodKey(entry), entry);
const delFood = entry => store.remove(foodKey(entry));

function setupStorage(initSamples) {
    if (!store.get('meta/version')) {
        store.clearAll(); // Start from a clean slate
        for (const entry of defaultFoodDB) {
            assert(entry.food, entry)
            assert(entry.carbohydrate, entry)
            if (initSamples) saveFood(entry)
        }
        store.set('meta/version', '0.1')
    }
}
(async function initStorage() {
    // Try to get Persistent Storage so our precious data aren't lost so easily
    if (navigator.storage && navigator.storage.persist && !persistGranted) {
        const persisted = await navigator.storage.persist();
        persistGranted = true; // let's not ask this again
        if (persisted)
            console.log("Storage will not be cleared except by explicit user action");
        else
            console.log("Storage may be cleared by the UA under storage pressure.");
    }
})();

function rowToObj(row) {
    const d={}
    for (const e of Array.from(row.querySelectorAll('input[name]'))) {
        if (e.name === 'quantity') continue;
        if (e.value.trim() !== '')
            d[e.name] = e.name==='food' ? e.value : +e.value
    }

    if (d.food) return d
}
async function saveRow(elem, modifiedAttribute, previousValue) {
    const d = rowToObj(elem)
    if (!d) return;

    // Must have at least one attribute to save.
    if (Object.keys(d).filter(x => x !== 'food' && x !== 'unit_size').length === 0) return;

    saveFood(d);

    const nameModified = modifiedAttribute === 'food' && previousValue;
    const refName = nameModified ? previousValue : d.food;

    // Keep the attributes in sync for all rows with the same ID:
    T.querySelectorAll('tr').forEach(row => {
        if (row.querySelector('input[name="food"]').value !== refName) return;
        row.querySelectorAll('input[name]').forEach(input => {
            if (input.name in d) input.value = d[input.name];
        })
    });

    // Rename: delete the previous value
    if (nameModified) {
        const old = {...d, food: previousValue}
        delFood(old);
    }

    updateDataList();
    calculateTotals();
}

// TODO: Add undo + prompt to undo
async function forgetRow(elem) {
    const d = rowToObj(elem)
    delFood(d);
    updateDataList();
    elem.remove();
    console.log(`Forgot ${d.food}`, d)
}

function clampInput(ev) {
    const elem = ev.target;
    let v = elem.value.trim()
    if (elem.type === 'number') {
        if (v !== '') v = Math.max(+elem.min, Math.min(+elem.max, +v))
        elem.value = v;
        return;
    }

    if (elem.maxlength && elem.length > +elem.maxlength) {
        v = v.slice(0, +elem.maxlength)
    }
}
function trimInput(ev) {
    const elem = ev.target;
    elem.value = elem.value.trim();
}

document.querySelector('#body').addEventListener('input', clampInput)
T.addEventListener('input', clampInput)
T.addEventListener('change', trimInput)

T.addEventListener('change', async ev => {
    // Save all changes ASAP
    const rowElem = getRowFromEvent(ev)
    await saveRow(rowElem, ev.target.name, ev.target.getAttribute('data-previous-value'))
    ev.target.setAttribute('data-previous-value', ev.target.value)
})

T.addEventListener('click', async ev => {
    if (ev.target.tagName !== 'BUTTON') return;

    const rowElem = getRowFromEvent(ev)
    if (ev.target.name === 'remove') rowElem.remove();
    if (ev.target.name === 'add') rowElem.insertAdjacentHTML('afterEnd', rowTemplate)
    if (ev.target.name === 'forget') await forgetRow(rowElem);

    // Ensure there's always at least one row:
    if (T.childElementCount === 0) T.appendChild(html(rowTemplate))
})

T.addEventListener('input', updateTotals)

const resetDB = initWithSamples => {
    store.clearAll();
    setupStorage(initWithSamples);
    T.querySelectorAll('tr').forEach(elem => elem.remove());
    calculateTotals();
    T.appendChild(html(rowTemplate));
    updateDataList();
}
document.querySelector('#factory-reset').addEventListener('click', () => {
    if (window.confirm('Do you want to reset the food database to initial sample values?'))
        resetDB(true);
})
document.querySelector('#clean-slate').addEventListener('click', () => {
    if (window.confirm('Do you want to remove all data from the food database?'))
        resetDB(false);
})


const setBodyFields = () => {
    if (!store.get('body')) return;

    const {
        weight,
        bodyFatRatio,
        dailyActivityLevel,
        energyAvailability,
        proteinIntakeActivityLevel,
    } = store.get('body')

    document.querySelector('#weight').value = weight
    document.querySelector('#bodyfat').value = Math.round(100*bodyFatRatio)
    document.querySelector(`[name="activity-level"][value="${dailyActivityLevel}"]`).checked = true;
    document.querySelector('#energy-availability').value = energyAvailability
    document.querySelector(`[name="protein-intake-activity-level"][value="${proteinIntakeActivityLevel}"]`).checked = true;

    const assumedEEE = +document.querySelector('[name="activity-level"]:checked').getAttribute('data-assumed-expenditure')
    document.querySelector('#exercise-energy-expenditure').value = assumedEEE
}

const updateRecommendations = ev => {
    const weight = document.querySelector('#weight').value
    const bodyFatRatio = +document.querySelector('#bodyfat').value / 100
    const dailyActivityLevel = document.querySelector('[name="activity-level"]:checked').value
    const energyAvailability = +document.querySelector('#energy-availability').value
    const proteinIntakeActivityLevel = document.querySelector('[name="protein-intake-activity-level"]:checked').value

    const assumedEEE = +document.querySelector('[name="activity-level"]:checked').getAttribute('data-assumed-expenditure')
    if (ev && ev.target.name === 'activity-level') {
        document.querySelector('#exercise-energy-expenditure').value = assumedEEE;
    }
    const statedEEE = +document.querySelector('#exercise-energy-expenditure').value
    const EEE = statedEEE || assumedEEE;

    store.set(`body`, {
        weight,
        bodyFatRatio,
        dailyActivityLevel,
        energyAvailability,
        proteinIntakeActivityLevel,
    })

    const es = {}
    document.querySelectorAll('#recommended-amounts td[data-name]')
    .forEach(e => { es[e.attributes['data-name'].value] = e; })

    const [carbMin, carbMax, carbPlus] = /([\d.]+)[^\d]+([\d.]+)(.*)/.exec(dailyActivityLevel).slice(1);
    const [protMin, protMax] = /([\d.]+)[^\d]+([\d.]+)/.exec(proteinIntakeActivityLevel).slice(1);

    // EA: energy availability
    // EI: energy intake
    // EEE: additional energy expenditure from exercise
    // FFM: fat-free body mass
    const FFM = weight * (1 - bodyFatRatio);
    // const EA = (EI - EEE) / FFM; // 30..45+ ok athletics-wise
    const EA = energyAvailability;
    const EI = EA*FFM + EEE;

    const calories = EI;

    // Total Energy Expenditure (TEE)
    // = Basal Metabolic Rate (BMR)
    // + the Thermic Effect of Food (TEF)
    // + the Thermic Effect of Activity (TEA)
    // const TEE = BMR + TEF + TEA;

    // const TEA =

    es.Carbohydrate.innerText = `${pp((+carbMin) * weight)}–${pp((+carbMax) * weight)}${carbPlus} g`
    es.Calories.innerText = `${pp(calories)} kcal`
    es.Protein.innerText = `${pp(protMin * weight)}–${pp(protMax * weight)} g`

    const fatEnergyDensity = 8.8; // kcal/g
    const sugarEnergyDensity = 3.87 // kcal/g (NB: unverified) - elsewhere: "4"
    const proteinEnergyDensity = 4 // kcal/g
    const carbohydrateEnergyDensity = 4 // kcal/g (elsewhere: 3.75..4.2)

    // Less than 15% seems to reduce testosterone levels too much: https://www.ncbi.nlm.nih.gov/pubmed/8942407
    // at most 30% according to https://www.who.int/news-room/fact-sheets/detail/healthy-diet
    es.Fat.innerHTML = `
    <abbr title="At most 30% of total energy intake (WHO guidelines)">
        ${pp(0.15 * calories / fatEnergyDensity)
        }–${pp(0.30 * calories / fatEnergyDensity)} g
    </abbr>
    `;  // max 30% of total energy intake

    // es.SaturatedFat.innerText = `max ${pp(0.10 * calories / fatEnergyDensity)}`; // max 10% of total energy intake
    // es.TransFat.innerText = `max ${pp(0.01 * calories / fatEnergyDensity)}`; // max 1% of total energy intake

    // es.Sugar.innerText = `max ${pp(0.05 * calories / fatEnergyDensity)}`; // max 5% of total energy intake for optimal health, max 10% otherwise

    // 14 g / 1000 kcal (US recommendation)
    es.Fiber.innerHTML = `
    <abbr title="14 g / 1,000 kcal (USDA Dietary Guidelines 2015-2020)">
        ${pp(14 * calories / 1000)} g
    </abbr>
    `

    // es.Salt.innerText = `<5 g (<2 g of sodium)`
}

setupStorage(true);
updateDataList();
T.appendChild(html(rowTemplate))

setBodyFields();
updateRecommendations();
calculateTotals();
document.querySelector('#body-measurements').addEventListener('input', updateRecommendations)
